import { GLOBAL_OBJ } from '@sentry/utils';

/**
 * An object that contains a hub and maintains a scope stack.
 * @hidden
 */

/**
 * Returns the global shim registry.
 *
 * FIXME: This function is problematic, because despite always returning a valid Carrier,
 * it has an optional `__SENTRY__` property, which then in turn requires us to always perform an unnecessary check
 * at the call-site. We always access the carrier through this function, so we can guarantee that `__SENTRY__` is there.
 **/
function getMainCarrier() {
  // This ensures a Sentry carrier exists
  getSentryCarrier(GLOBAL_OBJ);
  return GLOBAL_OBJ;
}

/** Will either get the existing sentry carrier, or create a new one. */
function getSentryCarrier(carrier) {
  if (!carrier.__SENTRY__) {
    carrier.__SENTRY__ = {
      extensions: {}
    };
  }
  return carrier.__SENTRY__;
}
export { getMainCarrier, getSentryCarrier };
