import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root',
})
export class DownloadService {
  public constructor(private readonly _configService: ConfigService) {}

  public getDownloadUrl(fileUrl: string): string {
    return `${this._configService.api}/api/v1/private/download?url=${encodeURIComponent(fileUrl)}`;
  }
}
