import { Inject, Injectable, LOCALE_ID, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ConfigService } from './config.service';
import { Env } from '../dto/enum/env.enum';

interface DataLayerWindow extends Window {
  dataLayer: any[];
  gtag: Function;
}

declare let window: DataLayerWindow;

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  public constructor(
    private readonly _configService: ConfigService,
    @Inject(LOCALE_ID) private readonly _localeId: string,
    @Inject(PLATFORM_ID) private readonly _platformId: string
  ) {
    if (isPlatformBrowser(this._platformId)) {
      window.dataLayer = window.dataLayer || [];
      if (Env.PROD === this._configService.env) {
        window.gtag = function (): void {
          window.dataLayer.push(arguments);
        };
      } else {
        window.gtag = (): void => {};
      }
      window.gtag('js', new Date());
      window.gtag('config', this._configService.googleTagId);
    }
  }

  public event(eventName: string, params: any): void {
    if (isPlatformBrowser(this._platformId)) {
      window.gtag('event', eventName, {
        ...params,
        locale: this._localeId,
        appVersion: this._configService.appVersion,
      });
    }
  }
}
