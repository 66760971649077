import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { SeverityLevel } from '@sentry/types/types/severity';
import * as Sentry from '@sentry/angular';
import { Scope, User } from '@sentry/angular';
import type { Primitive } from '@sentry/types/types/misc';
import { IFaultResponseDTO } from '../dto/response/fault/fault-response.dto';

export interface IError {
  error: any;
  severityLevel: SeverityLevel;
  transactionName: string;
  user?: User;
}

export interface IHttpClientError extends IError {
  error: HttpErrorResponse;
}

@Injectable({
  providedIn: 'root',
})
export class SentryService {
  public captureError(error: IError): void {
    Sentry.captureException(error.error, (scope: Scope): Scope => {
      scope.setLevel(error.severityLevel);
      scope.setTransactionName(error.transactionName);
      if (error.user) {
        scope.setUser(error.user);
      }
      return scope;
    });
  }

  public captureHttpClientError(httpClientError: IHttpClientError): void {
    const tags: { [key: string]: Primitive } = {};
    if (0 !== httpClientError.error.status) {
      const faultResponseDTO: IFaultResponseDTO | null = httpClientError.error
        .error as IFaultResponseDTO;
      if (faultResponseDTO?.appName && faultResponseDTO?.appVersion) {
        tags['backendName'] = faultResponseDTO.appName;
        tags['backendVersion'] = faultResponseDTO.appVersion;
      }
    }
    Sentry.captureException(httpClientError.error, (scope: Scope): Scope => {
      scope.setLevel(httpClientError.severityLevel);
      scope.setTags(tags);
      scope.setTransactionName(httpClientError.transactionName);
      if (httpClientError.user) {
        scope.setUser(httpClientError.user);
      }
      return scope;
    });
  }
}
